<template>
  <div class="aiz-user-panel">
    <div class="card">
      <form class="" >
        <div class="card-header row gutters-5">
          <div class="col">
            <h5 class="mb-0 h6">{{ $t('tong-zhi') }}</h5>
          </div>
        </div>
        <div class="card-body">
          <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between align-items- py-3" v-for="(item, i) in noticeList" :key="i">
              <div class="media text-inherit">
                <div class="media-body">
                  <p class="mb-1 text-truncate-2">
                     {{item.Content}}
                  </p>
                  <small class="text-muted">
                    {{item.CreateAt}}
                  </small>
                </div>
              </div>
            </li>
            <li class="list-group-item" v-if="noticeList.length == 0">
              <div class="py-4 text-center fs-16">{{ $t('mei-you-fa-xian-tong-zhi') }}</div>
            </li>
          </ul>
          <!-- <pageBar :totalPage="56" :page="51" v-if="noticeList.length > 0"></pageBar> -->
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { getNoticeList } from '@/api/index';
import pageBar from '@/components/page'
export default {
  components: {
    pageBar
  },
  data() {
    return {
    }
  },
  computed: {
    noticeList() {
      return this.$store.state.noticeList
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
       getNoticeList().then(res => {
        this.$store.state.noticeList = res.data.Items
      })
    }
  }
}
</script>